import { useEffect } from 'react'

import { ModalComponent } from '../../components/Modal'

import { useSimpleState } from '../../hooks/useSimpleState'

import './index.css'

const DealComponent = ({ value, config={}, events={} }) => {
    const {_state} = useSimpleState({
        showReportDeadModal: false,
        fetching: false
    });

    const handleClick = () => {
        _state.set('fetching', true)

        if (events.handleClick)
            events.handleClick(value)

        setTimeout(() => {
            _state.set('fetching', false)
        }, 500)
    }

    const handleClickDead = () => {
        _state.set('showReportDeadModal', true);

        if (events.handleClickDead)
            events.handleClickDead(value)
    }

    const handleReportDeadModalClose = () => {
        _state.set('showReportDeadModal', false);
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(value.couponPromoCode);
    }

    const displayDollar = (val) => {
        return `$${Number.parseFloat(val).toFixed(2)}`;
    }

    const displayPercent = (val) => {
        return `${Number.parseFloat(val).toFixed(2)}%`
    }

    const displayDiscountPercent = () => {
        const percent = (1 - (value.priceFinal / value.priceOriginal)) * 100
        
        return displayPercent(Number.parseFloat(percent).toFixed(2))
    }

    const displayPercentageOrAmount = () => {
        if (value.couponClickablePercentage) {
            return displayPercent(value.couponClickablePercentage)
        } else if (value.couponClickableAmount) {
            return displayPercent(value.couponClickableAmount)
        }
    }

    return (
        <div className={`c-Deal ${_state.get('fetching') ? 'disabled' : ''}`}>
            <div className="collection-item-wrapper">
                <div className="item-header">
                    {value.affiliate.name}
                </div>
                <div className="item-body">
                    {value.deadDate ? (
                        <div className="item-titleDead">
                            DEAD
                        </div>
                    ) : null}

                    <div className="item-title">
                        <span onClick={handleClick}>{value.name}</span>
                    </div>
                    <div className="item-image" style={{ backgroundImage: `url(${value.imageUrl})` }}></div>
                    <div className="item-description">{value.description}</div>
                    
                    {value.couponClickablePercentage || value.couponClickableAmount || value.couponRedeemableDescription || value.couponPromoCode ? (
                        <div className="item-instructions">
                            <div className="item-instructions-title"><strong>How To Get Discount Price</strong></div>
                            <ul className="item-instructions-list">
                                {value.couponClickablePercentage || value.couponClickableAmount ? (
                                    <li><i className="bi bi-chevron-right"></i>Click {displayPercentageOrAmount()} coupon on product page</li>
                                ) : null}
                                
                                {value.couponRedeemableDescription ? (
                                    <li><i className="bi bi-chevron-right"></i>{value.couponRedeemableDescription}</li>
                                ) : null}

                                {value.couponPromoCode ? (
                                    <li><i className="bi bi-chevron-right"></i>Enter <span className="item-copyToClipboard bubble bubble-blue" onClick={handleCopyToClipboard}>{value.couponPromoCode} <i className="bi bi-copy"></i></span> at checkout</li>
                                ) : null}
                            </ul>
                        </div>
                    ) : null}
                    
                    <div className="item-price">
                        <div className="item-price-discountPrice">{displayDollar(value.priceFinal)}</div>
                        <div className="item-price-originalPrice"><s>{displayDollar(value.priceOriginal)}</s></div>
                        <div className="item-price-discountPercent">
                            <span className="bubble bubble-red">-{displayDiscountPercent()}</span>
                        </div>
                    </div>
                    <div className="item-cta deal-itemViewDeal">
                        <button className="button" onClick={handleClick}><span className="button-icon"></span> <span className="button-title">View Deal</span> <span className="button-icon"><i className="bi bi-chevron-right"></i></span></button>
                    </div>
                    <div className="item-cta deal-itemReportDead">
                        <button className="button" onClick={handleClickDead}><span className="button-title">Report Dead</span></button>
                    </div>
                </div>
            </div>

            {_state.get('showReportDeadModal') ? (
                <ModalComponent title="Generic Modal" config={{
                    show: _state.get('showReportDeadModal'),
                    controls: (
                        <>
                            <button className="button" onClick={handleReportDeadModalClose}>
                                Cool!
                            </button>
                        </>    
                    )
                }} events={{
                    handleClose: handleReportDeadModalClose
                }}>
                    <div className="modal-padded centered">
                        Thanks for reporting this deal as dead!
                    </div>
                </ModalComponent>
            ) : null}
        </div>
    )
}

export { DealComponent }