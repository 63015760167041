import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { FetchHelper } from '../../helpers/fetch'

import { useAppContext } from '../useAppContext'
import { useSimpleState } from '../useSimpleState'

import dayjs from 'dayjs'

const useControlSearch = (props={}) => {
    const {ac} = useAppContext()

    const {_state} = useSimpleState({})

    const {_state: _params} = useSimpleState(props.params || {})

    const {_state: _results} = useSimpleState()

    const groupByDate = (res) => {
        let response = JSON.parse(JSON.stringify(res))
        let data = response.body.data
        let results = []
        let result = null
        let lastDate = null        
    
        data.forEach((item, index) => {
            let date = dayjs(item.date).format('YYYY-MM-DD')
    
            if (lastDate !== date) {
                if (result) {
                    results.push(result)
                }

                lastDate = date
                result = {
                    date,
                    items: []
                }
            }
    
            result.items.push(item)

            if (index === data.length-1) {
                results.push(result)
            }
        })
    
        response.body.data = results
    
        return response
    }

    const fetch = () => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals'
        })
            .then((res) => {
                if (res.status === 200) {
                    let results = groupByDate(res)

                    _results.set(results)
                }
            })
            .catch((res) => {
                console.log('control search fetch error', res)
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchClick = (item) => {
        return FetchHelper({
            url: '/api/v1/deals/click',
            options: {
                method: 'put',
                data: {
                    id: item.id
                }
            }
        })
            .then((res) => {
                return res
            })
            .catch((res) => {
                return res
            })
    }

    const fetchClickDead = (item) => {
        return FetchHelper({
            url: '/api/v1/deals/click_dead',
            options: {
                method: 'put',
                data: {
                    id: item.id
                }
            }
        })
            .then((res) => {
                return res
            })
            .catch((res) => {
                return res
            })
    }

    useEffect(() => {
        fetch()
    }, [_params.get()])

    const set = (key, params) => {
        _params.set({
            ..._params.get(),
            key: params
        })
    }

    return Object.freeze({
        _results,
        _params,
        _control: {
            fetch,
            fetchClick,
            fetchClickDead,
            set
        }
    })
}

useControlSearch.propTypes = {
    beforeFetch: PropTypes.func,
    afterFetch: PropTypes.func,
}

export { useControlSearch }