import PropTypes from 'prop-types'

import axios from 'axios'

const api = axios.create({
    baseURL: 'https://tommytricker-api.fycdeals.com',
    headers: {
        'Content-Type': 'application/json'
    }
})

const FetchHelper = ({ url, options = {}, config = {}, abortController = new AbortController() }) => {
    const getUserToken = () => {
        let user = JSON.parse(sessionStorage.getItem('user')) || {}

        return user.token
    }

    let opts = {
        ...options,
        headers: {
            'Accept': 'application/json',
            ...options.headers
        }
    }

    let token = getUserToken()

    if (token) {
        opts.headers['Authorization'] = `Bearer ${token}`
    }

    return api({
        url,
        ...opts,
        signal: abortController.signal,
    }).then((res) => {
        if (res.status === 200) {
            return {
                status: res.status,
                body: res.data,
                success: true,
            }
        }

        return {
            status: res.status,
            body: res.data,
            success: false,
        }
    }).catch((res) => {
        if (res.response.status === 401) {
            sessionStorage.setItem('redirectToLogin', true)
        } else {
            return {
                status: res.response.status,
                errors: res.response.data,
                success: false
            }
        }
    })
}

FetchHelper.propTypes = {
    url: PropTypes.string.isRequired,

    options: PropTypes.shape({
        method: PropTypes.oneOf(['get', 'post', 'put', 'patch', 'delete']),
        params: PropTypes.object,
        data: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.instanceOf(FormData),
            PropTypes.string,
        ]),
        headers: PropTypes.object,
        timeout: PropTypes.number,
        responseType: PropTypes.oneOf(['json', 'text', 'blob', 'arraybuffer']),
        withCredentials: PropTypes.bool,
        cancelToken: PropTypes.object,
        onUploadProgress: PropTypes.func,
        onDownloadProgress: PropTypes.func,
    }),

    config: PropTypes.shape({})
}

FetchHelper.defaultProps = {
    options: {
        method: 'get',
        headers: {},
    },

    config: {
        // autoFetch: true
    }
}

export { FetchHelper }
